<template>
  <div class="box">
      <strong>Status: </strong> <span
          :class="['spanCustom', ...cssClasses]"
          :disabled="disabled"
          @click.prevent="click"
        >
          <slot> </slot>
      </span>
    </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      required: true,
      validator: (val) =>
        [
          "primary",
          "white",
          "danger",
          "info",
          "info2",
          "info3",
          "link-info",
          "outline-info",
          "outline-info2",
          "outline-primary",
          "link",
          "outline-danger",
          "black",
          "accent_outline",
          "secondary",
          "secondary_outline",
          "active",
          "inactive",
        ].includes(val),
    },
  },
  computed: {
    cssClasses() {
      let classes = [];

      this.outline
        ? classes.push(`button-outline-${this.variant}`)
        : classes.push(`button-${this.variant}`);

      return classes;
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.box {
  margin-left: 10px;
}

.spanCustom {
  margin-left: 10px;
}

.button-primary {
  color: #000;
  cursor: auto;
  border: none;
}

.button-primary-inverted {
  color: #000;
  cursor: auto;
  border: none;
}

.button-white {
  width: auto;
  color: #777;
  background: transparent;
  cursor: auto;
  border: none;
}

.button-danger {
  background: #fff;
  color: #ff0c37;
  cursor: auto;
  border: none;
}

.button-accent_outline {
  color: #b71a2e;
  background: #ffffff;
  cursor: auto;
  border: none;
}

.button-warning {
  background: #fff;
  color: #ffcb37;
  cursor: auto;
  border: none;
}
.button-info {
  width: auto;
  background: #fff;
  color: #002363;
  cursor: auto;
  border: none;
}

//Secondary outline
.button-info2 {
  width: auto;
  color: #002e74;
  background: #ffffff;
  cursor: auto;
  border: none;
}

.button-info3 {
  width: auto;
  color: $gray_800;
  background: #fff;
  cursor: auto;
  border: none;
}

.button-active {
  width: auto;
  color: #2a63ab;
  background: #fff;
  cursor: auto;
  border: none;
}

.button-inactive {
  width: auto;
  color: #000;
  cursor: auto;
  border: none;
}

.button-black {
  width: auto;
  background: #fff;
  color: #020016;
  cursor: auto;
  border: none;
}

.button-link-info {
  background: #fff;
  color: #2a63ab;
  cursor: auto;
  border: none;
}

.button-link {
  background: #fff;
  color: #333;
  cursor: auto;
  border: none;
}

// Outlines

.button-outline-primary {
  background: transparent;
  color: $base-color;
  cursor: auto;
  border: none;
}

.button-outline-white {
  background: transparent;
  color: #777;

  cursor: auto;
  border: none;
}

.button-outline-danger {
  color: rgb(183, 26, 46);
  background: rgb(255, 255, 255);
  cursor: auto;
  border: none;
}

.button-outline-info {
  background: transparent;
  color: #2a63ab;
  cursor: auto;
  border: none;
}

.button-outline-info2 {
  background: #fff;
  color: #002e74;
  cursor: auto;
  border: none;
}

.button-outline-warning {
  background: transparent;
  color: #ffcb37;
  cursor: auto;
  border: none;
}

.button-outline-black {
  background: transparent;
  color: #000;
  cursor: auto;
  border: none;
}

.button-secondary {
  width: auto;
  color: #2a63ab;
  background: #fff;
  cursor: auto;
  border: none;
}

.button-secondary_outline {
  width: auto;
  color: #002e74;
  background: #ffffff;
  cursor: auto;
  border: none;
}

@media screen and (max-width: 768px) {
  .spanCustom {
    height: 45px;
    padding: 0 20px !important;
    font-size: 13px;
  }
}
</style>
