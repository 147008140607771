<template>
  <BaseModal
    name="modal-view-claim"
    size="lg"
    title=""
    @hidden="control = $event"
    @shown="openModal"
  >
    <b-col
      class="pa-4"
      style="justify-content: center"
      cols="12"
      md="12"
      v-if="!loading"
    >
      <div v-if="!loading">
        <div class="expande-horizontal centraliza-horizontal">
          <div
            v-if="step === 1"
            class="container-rel shaddows"
            style="
              width: 550px;
              display: flex;
              flex-wrap: wrap;
              background: #fff;
              min-height: 30vh;
            "
          >
            <b-col class="mb-4" cols="12" md="12" data-anima="top">
              <h4 class="mb-3 green--text title--text">
                {{ $t('seller.claim.forms.view_claim.text_231') }}
              </h4>
              <span
                class="grey--text"
                style="margin-top: 22px; subtitle--text padding-bottom: 12px"
                >{{ $t('seller.claim.forms.view_claim.text_232') }}</span
              >
            </b-col>
            <b-col cols="12" md="12" data-anima="top">
              <b-form-group
                :label="$t('seller.claim.forms.view_claim.text_233')"
                label-for="numero_reclamacao"
              >
                <b-form-input
                  id="numero_reclamacao"
                  name="numero_reclamacao"
                  :placeholder="$t('seller.claim.forms.view_claim.text_1756')"
                  maxlength="40"
                  v-model="get_claim_info.id"
                  v-validate="'required'"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="submit ? !errors.has('numero_reclamacao') : null"
                >
                  {{ $t('seller.claim.forms.view_claim.text_1757') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col class="mt-2" cols="12" md="12" data-anima="top">
              <b-form-group :label="$t('seller.claim.forms.view_claim.text_1758')" label-for="id_compra">
                <b-form-input
                  id="id_compra"
                  name="id_compra"
                  :placeholder="$t('seller.claim.forms.view_claim.text_1759')"
                  v-model="get_claim_info.sale_id"
                  v-validate="'required'"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="submit ? !errors.has('id_compra') : null"
                >
                  {{ $t('seller.claim.forms.view_claim.text_1760') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col class="mt-2" cols="12" md="12" data-anima="top">
              <b-form-group :label="$t('seller.claim.forms.view_claim.text_1761')" label-for="cpf_cliente">
                <b-form-input
                  id="cpf_cliente"
                  name="cpf_cliente"
                  :placeholder="$t('seller.claim.forms.view_claim.text_1762')"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  v-model="get_claim_info.cpf_cnpj"
                  v-validate="'required'"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="submit ? !errors.has('cpf_cliente') : null"
                >
                  {{ $t('seller.claim.forms.view_claim.text_1763') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" class="mt-4 px-3">
              <div class="expande-horizontal column centraliza-vertical">
                <!-- <div style="width: 400px;">
                        <b-tooltip
                            style="width: 100px;"
                            target="span-privacidade"
                            title="Fique tranquilo, apenas a Voomp e o vendedor podem ver os dados informados. Nada que você preencher é público."
                            placement="top"
                        />
                        <div class="expande-horizontal centraliza-vertical">
                            <img class="mr-2" style="height: 15px;" src="@/assets/img/icons/eye-off-blue.svg" />
                            <span class="grey--text" id="span-privacidade">
                                informações privadas
                            </span>
                        </div>
                    </div> -->
                <BaseButton
                  style="width: 100%"
                  :disabled="!canNext"
                  variant="secondary"
                  @click="fetch_claim_public"
                  v-if="!loading"
                >
                  {{ $t('seller.claim.forms.view_claim.text_234') }}
                </BaseButton>
                <BaseButton style="width: 100%" variant="secondary" v-else>
                  <b-spinner style="color: #fff"></b-spinner>
                </BaseButton>
              </div>
            </b-col>
          </div>
        </div>
        <div v-if="step === 2" class="expande-horizontal wrap">
          <b-col cols="12" md="12">
            <b-row>
              <b-col data-anima="top" cols="12" md="9">
                <h3 class="titulo-claim">
                  {{ get_claim.subjective }}
                </h3>
                <div class="expande-horizontal">
                  <div style="display: flex; flex-wrap: no-wrap">
                    <img
                      style="
                        width: 17px;
                        height: 24px;
                        padding-bottom: 3px;
                        margin-right: 6px;
                      "
                      src="@/assets/img/icons/calendario-black.svg"
                    />
                    <span class="grey--text sub-titulo-claim">
                      {{
                        moment(get_claim.created_at).format(
                          "DD/MM/YYYY [às] HH:mm"
                        )
                      }}
                    </span>
                    <strong class="ml-3 mr-1">ID: </strong>
                    <span class="grey--text sub-titulo-claim">
                      {{ get_claim.id }}
                    </span>
                    <strong v-if="get_claim.closed_date" class="ml-3 mr-1"
                      >Encerrada em:
                    </strong>
                    <span v-if="get_claim.closed_date" class="grey--text">
                      {{ moment(get_claim.closed_date).format("DD/MM/YYYY") }}
                    </span>

                  <!-- inicio -->
                          <StatusClaimSpan
                              class="centered"
                              variant="accent_outline"
                              v-if="!get_claim.status"
                            >
                              {{ $t('seller.claim.forms.view_claim.text_1764') }}
                        </StatusClaimSpan>

                        <StatusClaimSpan
                          class="centered"
                          variant="warning"
                          v-if="get_claim.status === 'close_solicitation'"
                        >
                          {{ $t('seller.claim.forms.view_claim.text_1765') }}
                        </StatusClaimSpan>
                        <StatusClaimSpan
                          class="centered"
                          variant="secondary"
                          v-if="get_claim.status === 'close'"
                        >
                          {{ $t('seller.claim.forms.view_claim.text_1766') }}
                        </StatusClaimSpan>
                        <StatusClaimSpan
                          class="centered"
                          variant="secondary"
                          v-if="get_claim.status === 'refund_pending'"
                        >
                          {{ $t('seller.claim.forms.view_claim.text_1767') }}
                        </StatusClaimSpan>
                        <StatusClaimSpan
                          class="centered"
                          variant="secondary"
                          v-if="get_claim.status === 'refunded'"
                        >
                          {{ $t('seller.claim.forms.view_claim.text_1768') }}
                        </StatusClaimSpan>
                        
                  <!-- fim -->
                  </div>
                </div>
              </b-col>
              
              <b-row class="mt-3">
                <b-col cols="12" style="padding-left: 27px">
                  <span
                    style="
                      font-weight: 500;
                      color: #2A63AB;
                      background: rgba(33, 51, 210, 0.1);
                      border-radius: 10px;
                      padding-top: 7px;
                      padding-bottom: 7px;
                      padding-right: 11px;
                      padding-left: 11px;
                      font-size: 12px;
                      margin-right: 10px;
                    "
                    >{{ getCategory(get_claim.category) }}</span
                  >
                  <span
                    style="
                      font-weight: 500;
                      color: #2A63AB;
                      background: rgba(33, 51, 210, 0.1);
                      border-radius: 10px;
                      padding-top: 7px;
                      padding-bottom: 7px;
                      padding-right: 11px;
                      padding-left: 11px;
                      font-size: 12px;
                    "
                    >{{ getCategory(get_claim.objective) }}</span
                  >
                </b-col>
              </b-row>
              <b-col cols="12" md="12">
                <p class="grey--text mt-3 mb-3 historia-reclamacao">
                  {{ get_claim.description }}
                </p>
              </b-col>
            </b-row>

            <b-row class="mt-5">
              <b-col cols="12" md="8" data-anima="top">
                <b-form-textarea
                  id="id_compra"
                  name="id_compra"
                  style="min-height: 100px"
                  maxlength="400"
                  placeholder="Escreva seu comentário..."
                  v-model="answer"
                  v-validate="'required'"
                  type="text"
                ></b-form-textarea>
                <span style="color: grey; font-size: 11px">
                  {{ answer.length }} / 400</span
                >
              </b-col>
              <b-col cols="12" md="1" data-anima="top">
                <label for="file-upload" class="custom-file-upload">
                  <img
                    style="
                      position: relative;
                      margin-left: 13px;
                      top: 15px;
                      width: 18px;
                    "
                    class="pointer"
                    src="@/assets/img/icons/paperclip.svg"
                  />
                </label>
                <input
                  @change="setFileToClaim"
                  accept="image/png, image/jpeg, image/jpg"
                  id="file-upload"
                  type="file"
                />
              </b-col>
              <b-col cols="12" md="3" data-anima="top">
                <button
                  v-if="!loading"
                  :disabled="answer.length ? false : true"
                  @click="answer_claim"
                  style="
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 45px;
                    display: flex;
                    border: none;
                    font-weight: 500;
                    color: #2A63AB;
                    background: rgba(33, 51, 210, 0.1);
                    border-radius: 5px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    padding-right: 11px;
                    padding-left: 11px;
                    font-size: 14px;
                  "
                >
                  <span class="blue--text">{{ $t('seller.claim.forms.view_claim.text_1769') }}</span>
                </button>
                <button
                  v-if="loading"
                  style="
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 45px;
                    display: flex;
                    border: none;
                    font-weight: 500;
                    color: #2A63AB;
                    background: rgba(33, 51, 210, 0.1);
                    border-radius: 5px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    padding-right: 11px;
                    padding-left: 11px;
                  "
                >
                  <b-spinner style="color: #fff"></b-spinner>
                </button>
              </b-col>
              <b-col cols="12" md="12" data-anima="top">
                <b-row v-if="!get_claim.status" class="expande-horizontal">
                  <b-col cols="12" md="12" data-anima="top" class="pl-3">
                    <button
                      v-if="personalizedLoading === 'encerramento'"
                      data-anima="bottom"
                      class="blue--text small--text btn-clean strong--text mr-2"
                    >
                      {{ $t('seller.claim.forms.view_claim.text_235') }}
                    </button>
                    <button
                      v-else
                      data-anima="top"
                      @click="personalizedLoading = 'encerramento'"
                      class="red--text small--text btn-clean mr-2"
                    >
                      {{ $t('seller.claim.forms.view_claim.text_236') }}
                    </button>
                    <button
                      v-if="personalizedLoading === 'encerramento'"
                      data-anima="bottom"
                      @click="close_claim_public"
                      class="
                        green--text
                        mr-2
                        small--text
                        btn-clean
                        strong--text
                      "
                    >
                      {{ $t('seller.claim.forms.view_claim.text_1770') }}
                    </button>
                    <button
                      v-if="personalizedLoading === 'encerramento'"
                      data-anima="bottom"
                      @click="personalizedLoading = ''"
                      class="red--text mr-2 small--text btn-clean strong--text"
                    >
                      {{ $t('seller.claim.forms.view_claim.text_1771') }}
                    </button>
                    <b-spinner
                      class="green--text"
                      small
                      v-if="personalizedLoading === 'encerramento-sim'"
                    ></b-spinner>
                  </b-col>
                  <b-col cols="12" md="12" data-anima="top" class="pl-3">
                    <b-container v-if="personalizedLoading === 'reembolso'">
                      <b-row>
                        <b-col>
                          <b-form-group label="Categoria" label-for="category">
                            <b-form-select
                              id="category"
                              v-model="category"
                              :options="categories"
                              name="category"
                              v-validate="'required'"
                            ></b-form-select>
                            <b-form-invalid-feedback
                              :state="!errors.has('category')"
                            >
                              {{ $t('seller.claim.forms.view_claim.text_1772') }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group label="Motivo" label-for="motive">
                            <b-form-textarea
                              id="motive"
                              placeholder="Opcional"
                              v-model="motive"
                              rows="3"
                              max-rows="8"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-container>
                    <button
                      v-else
                      data-anima="top"
                      @click="goToSale"
                      class="green--text small--text btn-clean"
                    >
                      {{ $t('seller.claim.forms.view_claim.text_1773') }}
                    </button>
                    <button
                      v-if="personalizedLoading === 'reembolso'"
                      data-anima="bottom"
                      @click="refund_claim_admin"
                      class="
                        green--text
                        small--text
                        btn-clean
                        mr-2
                        strong--text
                      "
                    >
                      {{ $t('seller.claim.forms.view_claim.text_1774') }}
                    </button>
                    <button
                      v-if="personalizedLoading === 'reembolso'"
                      data-anima="bottom"
                      @click="personalizedLoading = ''"
                      class="red--text small--text btn-clean mr-2 strong--text"
                    >
                      {{ $t('seller.claim.forms.view_claim.text_1775') }}
                    </button>
                    <b-spinner
                      class="green--text"
                      small
                      v-if="personalizedLoading === 'reembolso-sim'"
                    ></b-spinner>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row
              class="expande-horizontal mt-4 mb-4 pr-3"
              style="justify-content: space-between"
              v-if="get_answers.length"
            >
              <span class="grey--text mr-3 ml-3 mt-5 sub-titulo-claim"
                >{{ $t('seller.claim.forms.view_claim.text_1776') }}</span
              >
              <span
                @click="toggleShowOnlyFirstAnswer"
                style="
                  position: relative;
                  right: 0px;
                  cursor: pointer;
                  color: #2A63AB;
                  font-weight: 600;
                  font-size: 13px;
                "
                class="mt-5"
              >
                {{ $t('seller.claim.forms.view_claim.text_237') }}
              </span>
            </b-row>

            <b-row class="pl-1 mt-3 expande-horizontal">
              <b-col v-if="showOnlyFirstAnswer && get_answers.length" cols="12">
                <div class="timeline">
                  <div
                    class="container-timeline right"
                    :class="getClassByResponse(get_answers[0])"
                  >
                    <div class="content">
                      <div
                        class="expande-horizontal pb-4 pr-3"
                        style="width: 100%; justify-content: space-between"
                      >
                        <strong
                          v-if="get_answers[0].type === 'CLIENT'"
                          class="mr-3 ml-3 resposta-type"
                          >{{ $t('seller.claim.forms.view_claim.text_1777') }} -
                          {{
                            get_answers[0].client
                              ? get_answers[0].client.name
                              : ""
                          }}</strong
                        >
                        <strong
                          v-if="get_answers[0].type === 'SELLER'"
                          class="mr-3 ml-3 resposta-type"
                          >{{ $t('seller.claim.forms.view_claim.text_1778') }} -
                          {{
                            get_answers[0].user ? get_answers[0].user.name : ""
                          }}</strong
                        >
                        <strong
                          v-if="get_answers[0].type === 'GREENN'"
                          class="mr-3 ml-3 resposta-type"
                          >{{ $t('seller.claim.forms.view_claim.text_1779') }} -
                          {{
                            get_answers[0].user ? get_answers[0].user.name : ""
                          }}</strong
                        >
                        <span
                          style="
                            position: relative;
                            right: 0px;
                            cursor: pointer;
                          "
                          class="mt-5 grey--text sub-titulo-claim"
                        >
                          {{
                            moment(get_answers[0].created_at).format(
                              "DD/MM/YYYY [às] HH:mm"
                            )
                          }}
                        </span>
                      </div>
                      <div class="expande-horizontal pb-4 ml-3">
                        <p
                          class="grey--text resposta-type"
                          style="padding: 0px; margin: 0px; top: -5px"
                        >
                          {{ get_answers[0].answer }}
                        </p>
                      </div>
                      <div class="expande-horizontal pb-4 ml-3">
                        <a
                          class="link-arquive"
                          :href="att.attachment"
                          @click="openFile(att.attachment)"
                          target="_blank"
                          v-for="(att, index) in get_answers[0]
                            .attachment_answers"
                          :key="att.id"
                          style="
                            display: flex;
                            min-width: 200px;
                            width: 200px;
                            align-items: center;
                          "
                        >
                          <div class="link-arquive-dot-blue"></div>
                          {{ $t('seller.claim.forms.view_claim.text_1780') }}{{ index + 1 }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                v-if="!showOnlyFirstAnswer && get_answers.length"
                cols="12"
              >
                <div
                  v-for="item in get_answers"
                  :key="item.id"
                  class="timeline"
                >
                  <div
                    class="container-timeline right"
                    :class="getClassByResponse(item)"
                  >
                    <div class="content">
                      <!-- <div class="expande-horizontal wrap" style="align-items: flex-start; justify-content: flex-start;"> -->
                      <div
                        class="expande-horizontal pb-4 pr-3"
                        style="width: 100%; justify-content: space-between"
                      >
                        <div
                          class="expande-horizontal pb-0 pr-3"
                          style="justify-content: space-between"
                        >
                          <strong
                            style="position: relative; top: -4px"
                            v-if="item.type && item.type === 'CLIENT'"
                            class="mr-3 ml-3 resposta-type"
                            >{{ $t('seller.claim.forms.view_claim.text_1777') }} -
                            {{ item.client ? item.client.name : "" }}</strong
                          >
                          <strong
                            style="position: relative; top: -4px"
                            v-if="item.type && item.type === 'SELLER'"
                            class="mr-3 ml-3 resposta-type"
                            >{{ $t('seller.claim.forms.view_claim.text_1778') }} -
                            {{ item.user ? item.user.name : "" }}</strong
                          >
                          <strong
                            style="position: relative; top: -4px"
                            v-if="item.type && item.type === 'GREENN'"
                            class="mr-3 ml-3 resposta-type"
                            >{{ $t('seller.claim.forms.view_claim.text_1779') }} -
                            {{ item.user ? item.user.name : "" }}</strong
                          >
                          <span
                            style="
                              position: relative;
                              right: 0px;
                              cursor: pointer;
                            "
                            class="grey--text sub-titulo-claim"
                          >
                            {{
                              moment(item.created_at).format(
                                "DD/MM/YYYY [às] HH:mm"
                              )
                            }}
                          </span>
                        </div>
                        <div class="expande-horizontal pb-4 ml-3">
                          <p class="grey--text resposta-type">
                            {{ item.answer }}
                          </p>
                        </div>
                        <div class="expande-horizontal pb-4 ml-3">
                          <a
                            class="link-arquive"
                            :href="att.attachment"
                            @click="openFile(att.attachment)"
                            target="_blank"
                            v-for="(att, index) in item.attachment_answers"
                            :key="att.id"
                            style="
                              display: flex;
                              max-width: 100px;
                              justify-content: center;
                              padding: 7px;
                              align-items: center;
                              font-size: 13px;
                            "
                          >
                            <div class="link-arquive-dot-blue"></div>
                            {{ $t('seller.claim.forms.view_claim.text_1780') }}{{ index + 1 }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row
              v-if="!get_answers.length"
              class="Table-body justify-content-center"
            >
              <p class="nao-encontrado">{{ $t('seller.claim.forms.view_claim.text_238') }}</p>
            </b-row>
          </b-col>
        </div>
      </div>
    </b-col>
    <div v-if="loading" class="d-flex align-items-center">
      <b-spinner></b-spinner>
    </div>
    <template v-slot:footer="{}">
      <div></div>
    </template>
  </BaseModal>
</template>

<script>
import moment from "moment";
// import ClaimPublic from "@/services/resources/ClaimPublic";
// const service = ClaimPublic.build();
import StatusClaimSpan from "@/components/Seller/Claim/StatusClaimSpan";
import Claim from "@/services/resources/Claim";
import Refund from "@/services/resources/RefundService";
// import ClaimPublic from "@/services/resources/ClaimPublic";
// import Claim from "@/services/resources/Claim";
// const servicePublic = ClaimPublic.build();
const service = Claim.build();

const serviceAdmin = Claim.build();
const serviceRefund = Refund.build();
export default {
  components: {
    StatusClaimSpan,
  },
  data() {
    return {
      control: false,
      route: null,
      moment: moment,
      step: this.$route.path === "/" ? 1 : 2,
      showOnlyFirstAnswer: true,
      submit: false,
      answer: "",
      answerFile: "",
      get_claim: {},
      get_claim_info: {},
      client: {},
      get_answers: [],
      loading: false,
      personalizedLoading: "",
      motive: null,
      category: null,
      categories: [
        { value: null, text: this.$t('seller.claim.forms.view_claim.text_1781') },
        { value: "BUY_WRONG", text: this.$t('seller.claim.forms.view_claim.text_241') },
        { value: "GAVE_UP_BUYING", text: this.$t('seller.claim.forms.view_claim.text_242') },
        {
          value: "EXPECTATIONS_NOT_MEETED",
          text: this.$t('seller.claim.forms.view_claim.text_239'),
        },
        {
          value: "PRODUCT_NOT_DELIVERED",
          text: this.$t('seller.claim.forms.view_claim.text_240'),
        },
        { value: "DUPLICATE_PURCHASE", text: this.$t('seller.claim.forms.view_claim.text_1282')},
      ],
    };
  },

  computed: {
    canNext() {
      if (
        this.get_claim_info.cpf_cnpj &&
        this.get_claim_info.sale_id &&
        this.get_claim_info.id
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    control() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
      this.control = false;
    },
  },
  mounted() {
    if (
      this.$route.query.s_id &&
      this.$route.query.id
    ) {
      service
        .search({
          sale_id: this.$route.query.s_id,
          id: this.$route.query.id,
          cpf_cnpj: this.$route.query.doc,
          url: `/list/${this.$route.query.id}`,
        })
        .then((res) => {
          console.log(res);
          this.fetch_claim_admin(res.claim, res.answers, res.claim.client);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  methods: {
    goToSale() {
      const s_id = this.$route.query.s_id;
      if (s_id) {
        this.$router.push({
          path: '/vendas',
          query: { s_id: s_id, s: 1 }
        });
      }
    },
    getCategory(category) {
      let r = "";
      if (category) {
        switch (category) {
          case "DUPLICATE_PURCHASE": {
            r = this.$t('seller.claim.forms.view_claim.text_1782');
            break;
          }
          case "PRODUCT_NOT_DELIVERED": {
            r = this.$t('seller.claim.forms.view_claim.text_240');
            break;
          }
          case "EXPECTATIONS_NOT_MEETED": {
            r = this.$t('seller.claim.forms.view_claim.text_239');
            break;
          }
          case "GAVE_UP_BUYING": {
            r = this.$t('seller.claim.forms.view_claim.text_242');
            break;
          }
          case "BUY_WRONG": {
            r = this.$t('seller.claim.forms.view_claim.text_241');
            break;
          }
          default: {
            r = category;
            break;
          }
        }
        return r;
      } else return category;
    },
    openModal() {
      if (
        this.$route.query.s_id &&
        this.$route.query.id &&
        this.$route.query.doc
      ) {
        service
          .search({
            sale_id: this.$route.query.s_id,
            id: this.$route.query.id,
            cpf_cnpj: this.$route.query.doc,
            url: `/list/${this.$route.query.id}`,
          })
          .then((res) => {
            console.log(res);
            this.fetch_claim_admin(res.claim, res.answers, res.claim.client);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    fetch_claim_admin(payload, answers, client) {
      console.log(payload, answers, client);
      this.get_claim = payload;
      this.get_claim_info = payload;
      this.client = client;
      this.get_answers = answers.reverse();
      this.answerFile = "";
      this.step = 2;
      this.$bvModal.show("modal-view-claim");
    },
    close_claim_public() {
      this.personalizedLoading = "encerramento-sim";
      service
        .update(
          { cpf_cnpj: this.client.cpf_cnpj },
          `closeBySeller/${this.get_claim.id}`
        )
        .then((res) => {
          this.$bvToast.toast(this.$t('seller.claim.forms.view_claim.text_243'), {
            variant: "success",
            title: this.$t('seller.claim.forms.view_claim.text_1783'),
            autoHideDelay: 5000,
            // appendToast: true,
          });
          this.$bvModal.hide("modal-view-claim");
          this.personalizedLoading = "";
        })
        .catch((e) => {
          this.personalizedLoading = "";
          this.loading = false;
        });
    },
    close_claim_admin() {
      this.personalizedLoading = "encerramento-sim";
      serviceAdmin
        .search({ url: `/closeBySeller/${this.get_claim.id}` })
        .then((res) => {
          this.$bvToast.toast(this.$t('seller.claim.forms.view_claim.text_243'), {
            variant: "success",
            title: this.$t('seller.claim.forms.view_claim.text_1783'),
            autoHideDelay: 5000,
            // appendToast: true,
          });
          // this.$bvModal.hide("modal-view-claim");
          this.get_claim.status = "close_solicitation";
          this.personalizedLoading = "";
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    refund_claim_admin() {
      this.personalizedLoading = "reembolso-sim";
      const data = {
        sale_id: this.get_claim.sale_id,
        category: this.category,
        motive: this.motive,
      };
      serviceRefund
        .create(data)
        .then((res) => {
          this.$bvToast.toast(this.$t('seller.claim.forms.view_claim.text_1784'), {
            variant: "success",
            title: this.$t('seller.claim.forms.view_claim.text_1785'),
            autoHideDelay: 5000,
            // appendToast: true,
          });
          this.get_claim.status = "refund_pending";
          // this.$bvModal.hide("modal-view-claim");
          this.personalizedLoading = "";
        })
        .catch((e) => {
          this.loading = false;
          this.personalizedLoading = "";
        });
    },
    fetch_claim_public() {
      this.loading = true;
      service
        .search({
          sale_id: this.get_claim_info.sale_id,
          cpf_cnpj: this.client.cpf_cnpj,
          id: this.get_claim_info.id,
          url: `/list/${this.get_claim_info.id}`,
        })
        .then((res) => {
          if (res.claim) {
            this.get_claim = res.claim;
            this.get_answers = [];
            this.get_answers = res.answers.reverse();
            this.step = 2;
            this.answerFile = "";
            this.loading = false;
          } else {
            this.$bvToast.toast(this.$t('seller.claim.forms.view_claim.text_244'), {
              variant: "warning",
              title: this.$t('seller.claim.forms.view_claim.text_1786'),
              autoHideDelay: 5000,
              // appendToast: true,
            });
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    answer_claim() {
      this.loading = true;
      var formData = new FormData();
      formData.append("answer", this.answer);
      formData.append("attachment", this.answerFile);
      formData.append("claim_id", this.get_claim.id);
      formData.append("type", "SELLER");
      service
        .create(formData, null, `/answer/${this.get_claim.id}`)
        .then(() => {
          this.answer = "";
          this.answerFile = "";
          this.loading = false;
          this.$bvToast.toast(this.$t('seller.claim.forms.view_claim.text_1787'), {
            variant: "success",
            title: this.$t('seller.claim.forms.view_claim.text_1788'),
            autoHideDelay: 5000,
            // appendToast: true,
          });
        })
        .catch((e) => {
          this.loading = false;
        })
        .finally(() => {
          this.answer = "";
          this.answerFile = null;
          this.loading = false;
          this.fetch_claim_public();
        });
    },
    getClassByResponse(item) {
      const resp = {
        CLIENT: "color-resp-client",
        SELLER: "color-resp-seller",
        GREENN: "color-resp-greenn",
      };
      if (item) {
        return resp[item.type];
      } else {
        return "";
      }
    },
    toggleShowOnlyFirstAnswer() {
      this.showOnlyFirstAnswer = !this.showOnlyFirstAnswer;
    },
    uploadImageSuccess(formData, index, fileList) {
      this.answerFile = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        done();
        this.answerFile.splice(index, 1);
      }
    },
    setFileToClaim(e) {
      if (e.target.files[0].type.split("/")[0] !== "image") {
        this.$bvToast.toast(this.$t('seller.claim.forms.view_claim.text_245'), {
          variant: "danger",
          title: "Imagem",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        this.answerFile = e.target.files[0];
        this.$bvToast.toast(this.$t('seller.claim.forms.view_claim.text_246'), {
          variant: "success",
          title: "Imagem",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  background: #f7f7f7;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.avatar- {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  margin-right: 30px;
  display: flex;
  background: #37cf79;
  align-items: center;
  justify-content: center;
}
.red--text {
  color: #ff0c37;
}
.small--text {
  font-size: 12px;
}
.link-arquive {
  background: #f7f7f7;
  padding: 12px;
  border-radius: 5px;
  color: #333333;
  font-size: 14px;
  height: 35px;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 6px;
}
.link-arquive-dot-blue {
  width: 10px;
  height: 5px;
  border-radius: 5px;
  background: #002363;
  position: relative;
  margin-right: 6px;
}
.link-arquive-dot-red {
  width: 10px;
  height: 5px;
  border-radius: 5px;
  background: #ff0c37;
  position: relative;
  margin-right: 6px;
}
.btn-clean {
  border: none;
  background: rgba(0, 0, 0, 0);
}
.strong--text {
  font-weight: bold;
}
.green--text {
  color: #37cf79;
}
.blue--text {
  color: #2a63ab;
}
.grey--text {
  color: #81858e;
}
.spacer {
  width: 100%;
}
.button-text-blue {
  background: transparent;
  border: none;
  font-size: 10pt;
  text-align: left;
  font-weight: 700;
}
.box-info {
  border-radius: 6px;
  background: #f5f5f5;
  font-size: 11pt;
  color: #81858e;
  padding: 24px;
}
.shaddows {
  border-radius: 12px;
  box-shadow: 2px 2px 3px 3px #f2f2f2;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline::before {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #ededf0;
  top: 0;
  width: 1px;
  bottom: 0;
}

.container-timeline {
  padding-top: -20px;
  padding-left: 20px;
  position: relative;
  background-color: inherit;
}

.container-timeline::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 15px;
  top: 0px;
  border-radius: 50%;
  z-index: 1;
}
.color-resp-client::after {
  background-color: #ff0c37;
  box-shadow: 0px 0px 5px #ff0c37;
}
.color-resp-seller::after {
  background: #002363;
  box-shadow: 0px 0px 10px #002363;
}
.color-resp-greenn::after {
  background: #37cf79;
  box-shadow: 0px 0px 10px #37cf79;
}
.left {
  left: 0;
}
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  z-index: 1;
}

.right::after {
  left: -4px;
}

.content {
  background-color: white;
  position: relative;
  top: -5px;
}

@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  .container-timeline {
    width: 100%;
  }

  .container::before {
    left: 60px;
  }

  .right {
    left: 0%;
  }
}

/* novos estilos */
.titulo-claim {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}
.sub-titulo-claim {
  font-size: 14px;
  color: #81858e;
}
.resposta-type {
  font-size: 14px !important;
}
.icone-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.historia-reclamacao {
  font-size: 14px;
}
.centered {
  display: flex;
  justify-content: left;
}
</style>
